import React, { useEffect, useState, useRef, useCallback } from "react";
import { postRequest, postRequestFormData } from "../utils/httpUtils";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { v4 as uuidv4 } from "uuid";
import {
  NotificationType,
  useNotifications,
} from "../utils/notifications/Notifications";
import { secureFilename, isTellenUser } from "../utils";
import DynamicForm from "../DynamicForm";
import { parseFile } from "../utils/parseFile";
import { handleURLSubmit } from "../utils/handleURLSubmit";
import {
  getApp,
  getMyApps,
  appSelector,
  createApp,
} from "../redux/reducers/apps.reducer";
import { customizationsSelector } from "../redux/reducers/app.reducer";
import { handleFileUpload } from "../utils/FileUpload/handleFileUpload";
import { extract10KData } from "../utils/extract10KData";

const CreateApp = () => {
  const dispatch = useDispatch();
  const { app } = useParams();
  const urlInput = useRef(null);
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { user } = useUser();
  const appData = useSelector(appSelector);
  const appCustomizations = useSelector(customizationsSelector);

  const [inputValue, setInputValue] = useState("");
  const [enableGlobalValue, setEnableGlobalValue] = useState(false);
  // Initialize dynamicFields with an empty object or structured data but without pre-filled values
  const [dynamicFields, setDynamicFields] = useState({});
  // Use another state to track the values of the dynamic fields
  const [dynamicValues, setDynamicValues] = useState({});
  const [created, setCreated] = useState(false);
  const [filesDropped, setFilesDropped] = useState(false);
  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentPDFURL, setCurrentPDFURL] = useState(null); // TODO: This should not be necessary here at all, as we're not selecting individual rows.
  const [urlInputSubmitPressed, setURLInputSubmitPressed] = useState(false);
  const [pdfDetails, setPDFDetails] = useState(null);
  const [isParsing, setIsParsing] = useState(false);
  const [isUploading, setIsUploading] = useState([]);
  const [progressFiles, setProgressFiles] = useState([]);

  useEffect(() => {
    if (app) dispatch(getApp({ app }));
  }, [app]);

  useEffect(() => {
    if (appData) {
      const customizationTemplate = appData.customization_template || {};
      setDynamicFields(customizationTemplate);
      let initialValues = {};
      if (Array.isArray(customizationTemplate)) {
        customizationTemplate.forEach((field) => {
          initialValues[field.field_id] = field.default_value || "";
        });
        setDynamicValues(initialValues);
      }
    }
  }, [appData]);

  const handleDynamicInputChange = (e) => {
    const { id, value } = e.target;
    setDynamicValues((prev) => ({ ...prev, [id]: value }));
  };

  // TODO: Shall we split this into two functions?
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      let name = inputValue || `${appData.name}`;
      const body = {
        name: name,
        user: user,
        app_id: appData.app_id,
        customizations: dynamicValues,
        enableGlobal: enableGlobalValue,
      };
      const { payload } = await dispatch(createApp(body));
      if (payload) {
        setInputValue("");
        setCreated(true);
        dispatch(getMyApps({ user }));
        navigate(payload.path);
      }
    } catch (error) {
      console.error("Failed to create app:", error);
    }
  };

  const catchAction = ({ fileID }) => {
    setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
    setSuccessfulFileUploads((successfulFileUploads) =>
      successfulFileUploads.filter((file) => file.data["file_id"] !== fileID)
    );
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = () => {
        setFilesDropped(true);
        setPDFDetails(null);
      };
      const beforePostAction = ({ file }) => {
        setProgressFiles(prev => ([
          ...prev,
          {
            name: file.name,
            size: file.size,
            type: file.type,
            progress: 0
          }
        ]))
        setIsUploading(true);
      };
      const thenAction = ({ fileID, response }) => {
        setIsUploading(false);
        setIsParsing(true);
        setFileIDs([response.data["file_id"]]);
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        setURLInputSubmitPressed(false);
        if (
          appData.slug != "footnote_ai0" &&
          appData.slug != "footnotes_database"
        ) {
          // This is to parse any file for RAG
          parseFile(
            response.data["file_id"],
            "pdf",
            postRequest,
            setPDFDetails,
            addNotification,
            NotificationType,
            { catchAction, finalAction: () => setIsParsing(false) }
          );
        } else {
          // This is to parse 10Ks
          // COPIED FROM GENERICEXTRACTOR.JS WHEN LOOKING AT 10Ks. TODO: Refactor this into a function.
          try {
            console.log(
              "XG Extracting data from file ID:",
              response.data["file_id"]
            );
            extract10KData(response.data["file_id"], {
              onStatus: (jsonPayload) => {
                console.log("XG Status:", jsonPayload);
              },
              onFinal: (jsonPayload) => {
                if ("error" in jsonPayload.value) {
                  addNotification("No data found.", "", NotificationType.error);
                } else {
                  // addNewElement(fileID, {
                  //   financialStatements:
                  //     jsonPayload.value["financial_statements"],
                  //   footnotes: jsonPayload.value["footnotes"],
                  // });
                  // selectElementByFileID(fileID);
                  addNotification(
                    "Document parsed.",
                    "",
                    NotificationType.success
                  );
                  setIsParsing(false);
                }
              },
              onError: (error) => {
                console.error("XG Error:", error);
                addNotification(
                  "Error parsing document.",
                  "",
                  NotificationType.error
                );
              },
            });
          } catch (error) {
            addNotification(
              "Error submitting your query.",
              "",
              NotificationType.error
            );
            console.error("Error fetching data:", error);
          }
        }
      };

      const onUploadProgress = _progress => {
        const { size } = _progress.payload.get("file")
        const { progress } = _progress
        setProgressFiles(prev => prev.map(f => ({ ...f, progress: f.size === size ? progress : f.progress })))
      }

      handleFileUpload(
        acceptedFiles,
        { addNotification, fileType: "pdf", user },
        { preAction, thenAction, catchAction, beforePostAction, onUploadProgress }
      );
    },
    [fileIDs, user, appData]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("SUCCESSFUL FILE UPLOADS!");
      console.log(successfulFileUploads);
      setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
      setCurrentPDFURL(successfulFileUploads[0].data["url"]); // TODO: THis doesn't need to be set again and again, but it's okay for now.

      const lastFileId =
        successfulFileUploads[successfulFileUploads.length - 1].data.file_id;

      setDynamicValues((prev) => {
        return {
          ...prev,
          fileIDs: prev.fileIDs ? [...prev.fileIDs, lastFileId] : [lastFileId],
        };
      });
    } else {
      setCurrentPDFURL(null);
      setPDFDetails(null);
      setFileIDs([]);
      setFilesDropped(false);
    }
  }, [successfulFileUploads]);

  return (
    appData && (
      <div className="flex flex-col gap-4">
        <div>
          <h1 className="text-2xl font-bold">
            Create App: <b>{appData.name}</b>
          </h1>
          <p className="mb-5">{appData.description}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {!created ? (
            <form onSubmit={handleCreate} className="col-span-full">
              <label className="block text-gray-700 font-bold pr-4">
                Name:
              </label>
              <input
                type="text"
                className="shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={`${appData.name}`}
                value={inputValue}
                onChange={({ target }) => setInputValue(target.value)}
              />
              {(appCustomizations.environment !== "production" ||
                isTellenUser(user)) && (
                <>
                  <br />
                  <div className="mt-4">
                    <input
                      type="checkbox"
                      id="enableGlobalCheckbox"
                      name="enableGlobalCheckbox"
                      checked={enableGlobalValue}
                      onChange={({ target }) =>
                        setEnableGlobalValue(target.checked)
                      }
                      value="selected"
                    />
                    <label
                      htmlFor="enableGlobalCheckbox"
                      className="ml-2 text-gray-700"
                    >
                      Share app with colleagues.
                    </label>
                  </div>
                </>
              )}
              <br />
              <br />
              <DynamicForm
                dynamicFields={dynamicFields}
                dynamicValues={dynamicValues}
                onInputChange={handleDynamicInputChange}
                progressFiles={progressFiles}
                // This list of parameters is too long
                onDrop={onDrop}
                urlInput={urlInput}
                showDelete={true}
                isUploading={isUploading}
                isParsing={isParsing}
                fileIDs={fileIDs}
                handleURLSubmit={(event) =>
                  handleURLSubmit(event, {
                    urlInput,
                    uuidv4,
                    secureFilename,
                    postRequest,
                    user,
                    setFileIDs,
                    parseFile,
                    setSuccessfulFileUploads,
                    setURLInputSubmitPressed,
                    addNotification,
                    NotificationType,
                    setPDFDetails,
                    catchAction,
                    thenAction: () => setIsUploading(false),
                    finalAction: () => setIsParsing(false),
                  })
                }
                filesDropped={filesDropped}
                successfulFileUploads={successfulFileUploads}
                currentPDFURL={currentPDFURL}
                setCurrentPDFURL={setCurrentPDFURL}
                setSuccessfulFileUploads={setSuccessfulFileUploads}
                urlInputSubmitPressed={urlInputSubmitPressed}
              />
              <div className="flex items-center gap-2">
                <button
                  type="submit"
                  className="flex items-center py-2 px-3 text-base font-medium rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                  disabled={isParsing}
                >
                  Create
                </button>
              </div>
            </form>
          ) : null}
        </div>
      </div>
    )
  );
};

export default CreateApp;
