import ProgressBar from "../../utils/progressBar";
import GenerateFootnote from "./GenerateFootnote";
import { useState, useEffect, useMemo } from "react";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import GenericExtractor from "../GenericExtractor/GenericExtractor";
import Questions from "./Questions";
import { Link } from "react-router-dom";

const GenerateAFootnote = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { appInstanceData } = useAppInstanceData();

  const getStatus = (stepIndex, currentStep) => {
    if (stepIndex === currentStep) return "current";
    if (stepIndex < currentStep) return "completed";
    return "upcoming";
  };

  const stepNames = ["Input data", "Questions", "Generate footnote"];

  const steps = stepNames.map((name, index) => ({
    name,
    status: getStatus(index, currentStep),
  }));

  // FIRST STEP
  const [footnoteTopic, setFootnoteTopic] = useState("");
  const [accountingStandard, setAccountingStandard] = useState("");
  const [yearEnd, setYearEnd] = useState("");
  const [clientName, setClientName] = useState("");
  const handleFootnoteTopicChange = (event) => {
    setFootnoteTopic(event.target.value);
  };

  const handleAccountingStandardChange = (event) => {
    setAccountingStandard(event.target.value);
  };

  const handleYearEndChange = (event) => {
    setYearEnd(event.target.value);
  };

  const handleClientNameChange = (event) => {
    setClientName(event.target.value);
  };

  const [
    successfulFileUploadsFromUploadData,
    setSuccessfulFileUploadsFromUploadData,
  ] = useState([]);

  const [filesDroppedFromUploadData, setFilesDroppedFromUploadData] =
    useState(false);

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const inputToGenerateQuestions = useMemo(
    () => ({
      footnoteTopic,
      accountingStandard,
      yearEnd,
      clientName,
      files: successfulFileUploadsFromUploadData,
    }),
    [
      footnoteTopic,
      accountingStandard,
      yearEnd,
      clientName,
      successfulFileUploadsFromUploadData,
    ]
  );

  // CHOOSING TEMPLATE FOOTNOTES
  const [workpapersForFootnote, setWorkpapersForFootnote] = useState({});

  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  useEffect(() => {
    // Log the whole variable
    console.log(
      "AAAA successfulFileUploadsFromUploadData:",
      successfulFileUploadsFromUploadData
    );

    // Log the mapped list
    const fileIds = successfulFileUploadsFromUploadData?.map(
      (file) => file.data.file_id
    );
    console.log("AAAA Mapped file IDs:", fileIds);
  }, [successfulFileUploadsFromUploadData]);

  return (
    <>
      <ProgressBar steps={steps} onStepClick={handleStepClick} />
      <div className="flex justify-between w-full px-4 pb-1 mb-4">
        <div className="w-1/2 flex justify-start">
          {currentStep > 0 ? (
            <button
              onClick={prevStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Previous
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>

        <div className="w-1/2 flex justify-end">
          {currentStep < steps.length - 1 ? (
            <button
              onClick={nextStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Next
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>
        <div className="ml-2 flex justify-end">
          <Link to={`/app/edit/${appInstanceData.app_instance_id}`}>Edit Prompts</Link>
        </div>
      </div>

      {currentStep === 0 && (
        <>
          <form className="space-y-4 max-w-md mx-auto p-4">
            <div className="flex items-center justify-between">
              <label className="text-gray-700 font-medium">
                Footnote Topic
              </label>
              <select
                value={footnoteTopic}
                onChange={handleFootnoteTopicChange}
                className="w-1/2 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select a topic
                </option>
                <option value="Income Taxes">Income Taxes</option>
                <option value="Commitments and Contingencies">
                  Commitments and Contingencies
                </option>
                <option value="Leases">Leases</option>
                <option value="Summary of Significant Accounting Policies">
                  Summary of Significant Accounting Policies
                </option>
                <option value="Subsequent Events">Subsequent Events</option>
                <option value="Fair Value Measurements">
                  Fair Value Measurements
                </option>
                <option value="Related Party Transactions">
                  Related Party Transactions
                </option>
                <option value="Debt">Debt</option>
                <option value="Stockholders' Equity">
                  Stockholders' Equity
                </option>
              </select>
            </div>

            <div className="flex items-center justify-between">
              <label className="text-gray-700 font-medium">
                Accounting Standards
              </label>
              <select
                value={accountingStandard}
                onChange={handleAccountingStandardChange}
                className="w-1/2 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select a standard
                </option>
                <option value="US GAAP">US GAAP</option>
                <option value="IFRS">IFRS</option>
              </select>
            </div>

            <div className="flex items-center justify-between">
              <label className="text-gray-700 font-medium">Year End</label>
              <input
                type="number"
                value={yearEnd}
                onChange={handleYearEndChange}
                placeholder="Enter year end"
                className="w-1/2 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="flex items-center justify-between">
              <label className="text-gray-700 font-medium">Client Name</label>
              <input
                type="text"
                value={clientName}
                onChange={handleClientNameChange}
                placeholder="Enter client name"
                className="w-1/2 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </form>
          <p>Select the files you'd like to upload.</p>
          <GenericExtractor
            successfulFileUploads={successfulFileUploadsFromUploadData}
            setSuccessfulFileUploads={setSuccessfulFileUploadsFromUploadData}
            filesDropped={filesDroppedFromUploadData}
            setFilesDropped={setFilesDroppedFromUploadData}
            type="upload"
            showHeader={false}
          />
        </>
      )}
      {currentStep === 1 && (
        <Questions
          inputToGenerateQuestions={inputToGenerateQuestions}
          questions={questions}
          setQuestions={setQuestions}
          setAnswers={setAnswers}
          appInstanceID={appInstanceData.app_instance_id}
        />
      )}
      {currentStep === 2 && (
        <GenerateFootnote
          firstPageAnswers={{
            appInstanceID: appInstanceData.app_instance_id,
            footnoteTopic: footnoteTopic,
            accountingStandard: accountingStandard,
            yearEnd: yearEnd,
            clientName: clientName,
            files: successfulFileUploadsFromUploadData?.map(
              (file) => file.data.file_id
            ),
          }}
          files={successfulFileUploadsFromUploadData?.map(
            (file) => file.data.file_id
          )}
          appInstanceID={appInstanceData.app_instance_id}
          questions={questions}
          answers={answers}
        />
      )}

      <div className="flex justify-between w-full px-4 pb-1 mt-4">
        <div className="w-1/2 flex justify-start">
          {currentStep > 0 ? (
            <button
              onClick={prevStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Previous
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>

        <div className="w-1/2 flex justify-end">
          {currentStep < steps.length - 1 ? (
            <button
              onClick={nextStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Next
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default GenerateAFootnote;
