import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { postRequest, ssePostRequest } from "../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  NotificationType,
  useNotifications,
} from "../utils/notifications/Notifications";
import { secureFilename, isTellenUser } from "../utils";
import DynamicForm from "../DynamicForm";
import { parseFile } from "../utils/parseFile";
import { handleURLSubmit } from "../utils/handleURLSubmit";
import {
  getMyApps,
  getApp,
  appSelector,
  getFilesByAppId,
  filesByAppSelector,
  updateApp,
} from "../redux/reducers/apps.reducer";
import { customizationsSelector } from "../redux/reducers/app.reducer";
import { useAppInstanceData } from "../hooks/useAppInstanceData";
import { handleFileUpload } from "../utils/FileUpload/handleFileUpload";
import ApprovedQuestionsAnswers from "../apps/generalDataChatAnalyzer/ApprovedQuestionsAnswers";
import DeleteAppButton from "../utils/DeleteAppButton";
import TenKViewer from "../apps/GenericExtractor/TenKViewer";
import FilesForFootNotesTable from "../apps/footnotesDatabase/FilesForFootNotesTable";
import { markFileAsOld, markFileAsParsing } from "../utils/utils";

const EditApp = () => {
  const dispatch = useDispatch();
  const urlInput = useRef(null);
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { user } = useUser();
  const appData = useSelector(appSelector);
  const appFiles = useSelector(filesByAppSelector);
  const appCustomizations = useSelector(customizationsSelector);

  const [inputValue, setInputValue] = useState("");
  const [enableGlobalValue, setEnableGlobalValue] = useState(false);
  // Initialize dynamicFields with an empty object or structured data but without pre-filled values
  const [dynamicFields, setDynamicFields] = useState({});
  // Use another state to track the values of the dynamic fields
  const [dynamicValues, setDynamicValues] = useState({});
  const [created, setCreated] = useState(false);
  const [filesDropped, setFilesDropped] = useState(false);
  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentPDFURL, setCurrentPDFURL] = useState(null); // TODO: This should not be necessary here at all, as we're not selecting individual rows.
  const [urlInputSubmitPressed, setURLInputSubmitPressed] = useState(false);
  const [pdfDetails, setPDFDetails] = useState(null);
  const [filesParsing, setFilesParsing] = useState([]);
  const [progressFiles, setProgressFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isParsing, setIsParsing] = useState(false);
  const [checkParse, setCheckParse] = useState(true);
  const { appInstanceData: appInstance, instanceId: app_instance_id } =
    useAppInstanceData();

  useEffect(() => {
    if (appInstance && appInstance.app_id) {
      dispatch(getApp({ app_id: appInstance.app_id }));
      dispatch(getFilesByAppId(appInstance.app_instance_id));
      setInputValue(appInstance.custom_name);
      setDynamicFields(appInstance.customization_template);
      setDynamicValues(appInstance.customizations);
      setEnableGlobalValue(appInstance.enable_global);
    }
  }, [appInstance.app_id]);

  /**
   * NOTE: Type definition for successfulFileUploads
   * TODO: Add to a documenation file
   * type SuccessfulFileUpload = {
   *   data: {
   *     file_id: string;
   *     filename: string;
   *     filetype: string;
   *     url: string;
   *   };
   *   isOldFile?: boolean;
   *   isParsing?: boolean;
   * };
   */
  useEffect(() => {
    if (appFiles) {
      setSuccessfulFileUploads(appFiles.map((file) => ({ data: file, isOldFile: true })));
    }
  }, [appFiles]);

  const handleDynamicInputChange = (e) => {
    const { id, value } = e.target;
    setDynamicValues((prev) => ({ ...prev, [id]: value }));
  };

  const onDelete = (fileID) => {
    console.log("Deleting file with ID: ", fileID);
    setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
    setSuccessfulFileUploads((successfulFileUploads) =>
      successfulFileUploads.filter((file) => file.data["file_id"] !== fileID)
    );
    setDynamicValues((prev) => {
      return {
        ...prev,
        fileIDs: prev.fileIDs.filter((id) => id !== fileID),
      };
    });
  };

  const onExtractFile = (fileID) => {
    markFileAsOld(setSuccessfulFileUploads, fileID);
  }

  const onStartExtract = (fileID) => {
    markFileAsParsing(setSuccessfulFileUploads, fileID);
  }

  const currentSource = useMemo(() => successfulFileUploads.find(
    (f) => f.data.url === currentPDFURL
  ), [successfulFileUploads, currentPDFURL]);

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      let name = inputValue || `${appData.name}`;
      const payload = {
        name: name,
        user: user,
        app_id: appData.id,
        customizations: dynamicValues,
        enableGlobal: enableGlobalValue,
      };
      const response = await dispatch(
        updateApp({ appId: app_instance_id, payload })
      );
      setInputValue("");
      setCreated(true);
      await dispatch(getMyApps({ user }));
      navigate(response.payload.path);
    } catch (error) {
      console.error("Failed to create app:", error);
    }
  };

  const catchAction = ({ fileID }) => {
    setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
    setSuccessfulFileUploads((successfulFileUploads) =>
      successfulFileUploads.filter((file) => file.data["file_id"] !== fileID)
    );
    setFilesParsing((previousFiles) =>
      previousFiles.filter((id) => id !== fileID)
    );
  };
  const beforePostAction = ({ file }) => {
    setProgressFiles(prev => ([
      ...prev,
      {
        name: file.name,
        size: file.size,
        type: file.type,
        progress: 0
      }
    ]))
    setIsUploading(true);
  };
  const finalAction = ({ fileID }) => {
    setFilesParsing((previousFiles) =>
      previousFiles.filter((id) => id !== fileID)
    );
    setIsParsing(false);
  };
  const onReset = ({ fileID }, shouldResetProgress) => {
    console.trace("Calling on reset with fileID: ", fileID);
    if (shouldResetProgress) {
      const file = successfulFileUploads.find(
        (file) => file.data["file_id"] === fileID
      );
      if (file) {
        const filename = file.data["filename"];
        setProgressFiles(prev => prev.filter(f => f.name !== filename));
      }
      setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
      setSuccessfulFileUploads((successfulFileUploads) => successfulFileUploads.filter((file) => file.data["file_id"] !== fileID));
      setProgressFiles([])
    }
    finalAction({ fileID });
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 5) {
        setCheckParse(false);
      }
      
      const preAction = () => {
        setPDFDetails(null);
        setFilesDropped(true);
      };

      const thenAction = ({ response, file }) => {
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        setIsUploading(false);
        setFileIDs([response.data["file_id"]]);
        setURLInputSubmitPressed(false);
        if (acceptedFiles.length < 6 || checkParse) {
          setIsParsing(true);
          console.log("APP DATA SLUG, ", appData.slug);
          setFilesParsing((previousFiles) => [
            ...previousFiles,
            response.data["file_id"],
          ]);
          if (
            appData.slug != "footnote_ai0" &&
            appData.slug != "footnotes_database"
          ) {
            // This is to parse any file for RAG
            parseFile(
              response.data["file_id"],
              "pdf",
              postRequest,
              setPDFDetails,
              addNotification,
              NotificationType,
              { catchAction, finalAction }
            );
          }
        }
      };

      const onUploadProgress = _progress => {
        const { size } = _progress.payload.get("file")
        const { progress } = _progress
        setProgressFiles(prev => prev.map(f => ({ ...f, progress: f.size === size ? progress : f.progress })))
      }

      handleFileUpload(
        acceptedFiles,
        { addNotification, fileType: "pdf", user, appInstanceID: app_instance_id },
        { preAction, thenAction, catchAction, beforePostAction, onUploadProgress }
      );
    },
    [fileIDs, user, app_instance_id]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
      const lastFileUploadIndex = successfulFileUploads.length - 1;
      setCurrentPDFURL(successfulFileUploads[lastFileUploadIndex].data.url); // TODO: THis doesn't need to be set again and again, but it's okay for now.

      setDynamicValues((prev) => {
        return {
          ...prev,
          fileIDs: successfulFileUploads.map((file) => file.data["file_id"]),
        };
      });
    } else {
      setCurrentPDFURL(null);
      setPDFDetails(null);
      setFileIDs([]);
      setFilesDropped(false);
    }
  }, [successfulFileUploads]);

  const navigateToPublishApp = useCallback(() => {
    navigate(`/app/publish/${app_instance_id}`);
  }, [navigate, app_instance_id]);

  const isFootnoteDB = appData?.slug === "footnotes_database";

  return (
    appInstance &&
    appData && (
      <div className="flex flex-col gap-4">
        <div>
          <h1 className="text-2xl font-bold">
            Edit App: <b>{appData.name}</b>
          </h1>
          <p className="mb-5">{appData.description}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {!created ? (
            <form onSubmit={handleEdit} className="col-span-full">
              <label className="block text-gray-700 font-bold pr-4">
                Name:
              </label>
              <input
                type="text"
                className="shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={`${appData.name}`}
                value={inputValue}
                onChange={({ target }) => setInputValue(target.value)}
              />
              {(appCustomizations.environment !== "production" ||
                isTellenUser(user)) && (
                <>
                  <br />
                  <div className="mt-4">
                    <input
                      type="checkbox"
                      id="enableGlobalCheckbox"
                      name="enableGlobalCheckbox"
                      checked={enableGlobalValue}
                      onChange={({ target }) =>
                        setEnableGlobalValue(target.checked)
                      }
                      value="selected"
                    />
                    <label
                      htmlFor="enableGlobalCheckbox"
                      className="ml-2 text-gray-700"
                    >
                      Share app with colleagues.
                    </label>
                  </div>
                </>
              )}
              <br />
              <br />
              <DynamicForm
                dynamicFields={dynamicFields}
                dynamicValues={dynamicValues}
                onInputChange={handleDynamicInputChange}
                progressFiles={progressFiles}
                // This list of parameters is too long
                onDrop={onDrop}
                urlInput={urlInput}
                showDelete={true}
                fileIDs={fileIDs}
                isParsing={isParsing}
                isUploading={isUploading}
                handleURLSubmit={(event) =>
                  handleURLSubmit(event, {
                    urlInput,
                    uuidv4,
                    secureFilename,
                    postRequest,
                    user,
                    setFileIDs,
                    parseFile,
                    setSuccessfulFileUploads,
                    setURLInputSubmitPressed,
                    addNotification,
                    NotificationType,
                    setPDFDetails,
                    catchAction,
                    finalAction,
                  })
                }
                filesDropped={filesDropped}
                successfulFileUploads={successfulFileUploads}
                currentPDFURL={currentPDFURL}
                setCurrentPDFURL={setCurrentPDFURL}
                setSuccessfulFileUploads={setSuccessfulFileUploads}
                urlInputSubmitPressed={urlInputSubmitPressed}
                onDelete={onDelete}
                shouldParse={checkParse}
                setShouldParse={setCheckParse}
                showSourcesTable={!isFootnoteDB}
              />

              {appData.slug === "general_data_chat" && (
                <>
                  <hr />
                  <ApprovedQuestionsAnswers
                    instance_id={app_instance_id}
                    successfulFileUploads={successfulFileUploads}
                    dynamicValues={dynamicValues}
                  />
                </>
              )}
              {isFootnoteDB &&
                currentPDFURL &&
                successfulFileUploads && (
                  <>
                    <FilesForFootNotesTable
                      instance_id={app_instance_id}
                      successfulFileUploads={successfulFileUploads}
                      onRowClick={(item) => setCurrentPDFURL(`/api/files/${item.file_id}.pdf`)}
                      currentPDFURL={currentPDFURL}
                    />
                    <TenKViewer
                      currentSource={currentSource}
                      successfulFileUploads={successfulFileUploads}
                      onReset={onReset}
                      onExtractFile={onExtractFile}
                      onStartExtract={onStartExtract}
                      checkParse={checkParse}
                    />
                  </>
                )}
              {appData.slug === "footnote_ai0" && (
                <>
                  <hr />
                  {/* Here we put the table specific to the old FootnoteAI */}
                </>
              )}
              <div className="flex items-center gap-2">
                <button
                  type="submit"
                  className="flex items-center py-2 px-3 text-base font-medium rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                  disabled={isParsing}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={navigateToPublishApp}
                  className="flex items-center py-2 px-3 text-base font-medium rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                  disabled={isParsing}
                >
                  Publish App
                </button>
                {!appInstance.enable_global && (
                  <DeleteAppButton app_instance_id={app_instance_id} />
                )}
              </div>
            </form>
          ) : null}
        </div>
      </div>
    )
  );
};

export default EditApp;
