import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import {
  getAppInstance,
  appInstanceSelector,
  loadingSelector
} from '../redux/reducers/apps.reducer';

export const useAppInstanceData = () => {
  const dispatch = useDispatch()
  const { instance_id } = useParams();

  const appInstance = useSelector(appInstanceSelector)
  const loading = useSelector(loadingSelector)

  useEffect(() => {
    if (instance_id) dispatch(getAppInstance({ app_instance_id: instance_id }))
  }, [instance_id])

  return {
    appInstanceData: appInstance || {},
    instanceId: instance_id,
    isLoading: loading,
    error: null
  };
};
