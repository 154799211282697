import { ssePostRequest, getRequest } from "./httpUtils";

export const extract10KData = async (fileID, onEventHandlers) => {
    const payload = {
        file: fileID,
    }
    return ssePostRequest(
        "/api/10k/extract",
        payload,
        onEventHandlers)
}

export const getFootnotesFor10K = async (fileID) => {
    const response = await getRequest(`/api/10k/get_footnote/${fileID}`);
    return response.data;
}
