import { useState, useEffect, useCallback } from "react";
import {
  HomeIcon,
  ComputerDesktopIcon,
  UserPlusIcon,
  WrenchIcon,
  BuildingLibraryIcon,
  ChatBubbleLeftIcon,
  EllipsisVerticalIcon,
  EyeSlashIcon,
  ChevronDownIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { myAppsSelector, getMyApps } from "../redux/reducers/apps.reducer";
import { customizationsSelector } from "../redux/reducers/app.reducer";
import {
  selectConversationsList,
  getConversationsHistory,
} from "../redux/reducers/conversations.reducer";
import useToggleAppInstanceVisibility from "../hooks/useToggleAppInstanceVisibility";
import CustomNavLink from "./CustomNavLink";
import createSubmenu from "./createSubmenu";
import { isTellenUser } from "../utils";

const SubItem = ({ subItem, location, hidden = false }) => {
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState("");

  const toggleDropdown = useCallback(
    () => setExpanded((expanded) => !expanded),
    [setExpanded]
  );
  const { hideAppInstance, showAppInstance } = useToggleAppInstanceVisibility(
    subItem.app_id
  );
  const onHideAppInstance = useCallback(() => {
    hideAppInstance();
    setExpanded(false);
  }, [hideAppInstance, setExpanded]);
  const onShowAppInstance = useCallback(() => {
    showAppInstance();
    setExpanded(false);
  }, [showAppInstance, setExpanded]);
  const onMouseEnter = useCallback(
    () => setHovered(subItem.label),
    [subItem.label, setHovered]
  );
  const onMouseLeave = useCallback(() => setHovered(""), [setHovered]);

  return (
    <li>
      <CustomNavLink
        to={subItem.to}
        isActive={location.pathname === subItem.to}
        disabled={subItem.disabled}
        className="relative w-56 flex items-center" // Additional custom class if needed
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="flex flex-row items-center justify-between w-full">
          <span className="text-white pl-2">{subItem.label}</span>
          {expanded && (
            <div className="bottom-0 right-0 bg-white text-black hover:bg-black hover:text-white shadow-md rounded-md z-10 flex justify-center bg-customHighlightColor">
              {!hidden && (
                <button
                  type="button"
                  onClick={onHideAppInstance}
                  className="flex gap-2 items-center justify-center"
                >
                  <EyeSlashIcon
                    className="h-6 w-6 bg-customHighlightColor text-white p-1 rounded"
                    aria-hidden="true"
                  />
                </button>
              )}
              {hidden && (
                <button
                  type="button"
                  onClick={onShowAppInstance}
                  className="flex gap-2 items-center justify-center"
                >
                  <EyeIcon
                    className="h-6 w-6 bg-customHighlightColor text-white p-1 rounded"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
          )}
          {(expanded || hovered === subItem.label) && (
            <span className="flex-none">
              <EllipsisVerticalIcon
                className="h-6 w-6 shrink-0"
                aria-hidden="true"
                onClick={toggleDropdown}
              />
            </span>
          )}
        </div>
      </CustomNavLink>
    </li>
  );
};

const SubMenu = ({ item, location }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleDropdown = useCallback(
    () => setExpanded((expanded) => !expanded),
    [setExpanded]
  );
  if (!item.submenu || Object.entries(item.submenu).length === 0) return null;
  const hasHiddenApps = item.submenu.hiddenApps.length > 0;
  return (
    <>
      <ul className="ml-1 space-y-1">
        {item.submenu.visibleApps.map((subItem) => (
          <SubItem subItem={subItem} location={location} key={subItem.to} />
        ))}
        {hasHiddenApps && (
          <li className="h-1">
            <span
              className="transition-colors duration-300 no-underline hover:bg-customHighlightColor group
              flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer text-customLightGray
              hover:bg-customHighlightColor active"
              onClick={toggleDropdown}
            >
              More...{" "}
              <ChevronDownIcon
                className="h-6 w-6 shrink-0"
                aria-hidden="true"
              />
            </span>
            {expanded &&
              item.submenu.hiddenApps.map((subItem) => (
                <SubItem
                  subItem={subItem}
                  location={location}
                  key={subItem.to}
                  hidden
                />
              ))}
          </li>
        )}
      </ul>
    </>
  );
};

const Navigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useUser();
  const appData = useSelector(myAppsSelector);
  const { name: companyName } = useSelector(customizationsSelector);
  const conversations = useSelector(selectConversationsList);

  const [isExpanded, setIsExpanded] = useState(false);

  const [navigation, setNavigation] = useState([
    { label: "Home", to: "/", icon: HomeIcon, exact: true },
    {
      label: "App Builder",
      icon: WrenchIcon,
      exact: true,
      to: "/apps_l1",
      disabled: false,
    },
    {
      label: "App Store",
      to: "/apps_l2",
      icon: ComputerDesktopIcon,
      exact: true,
    },
    {
      label: `${companyName} Apps`,
      icon: BuildingLibraryIcon,
      exact: true,
      to: "/company_apps",
      disabled: false,
    },
    {
      label: "My Apps",
      icon: UserPlusIcon,
      exact: true,
      to: "/myapps",
      disabled: false,
    },
  ]);

  useEffect(() => {
    console.log("Checking whether to hide company apps.");
    if (
      (window.location.hostname.endsWith(".tellen.ai") ||
        window.location.hostname.endsWith("localhost") ||
        window.location.hostname.endsWith("tellenstaging.com")) &&
      !isTellenUser(user)
    ) {
      // setNavigation((prevNavigation) =>
      //   prevNavigation.filter((item) => item.label !== `${companyName} Apps`)
      // );
      console.log("We're here and NOT hiding company apps!");
    }
  }, [user, companyName]);

  useEffect(() => {
    if (user) {
      dispatch(getMyApps({ user }));
      dispatch(getConversationsHistory());
    }
  }, [user]);

  useEffect(() => {
    if (appData) {
      setNavigation((prev) => {
        return prev.map((item) => {
          if (item.to === "/myapps" || item.to === "/company_apps") {
            return {
              ...item,
              submenu: createSubmenu(
                appData,
                item.to === "/myapps" ? "my_apps" : "company_apps"
              ),
            };
          }
          return item;
        });
      });
    }
  }, [appData]);

  return (
    <nav className="flex flex-1 flex-col">
      <ul className="flex flex-1 flex-col gap-y-7">
        <ul className="-mx-10 space-y-1">
          {navigation.map((item, index) => (
            <li key={item.label}>
              <CustomNavLink
                to={item.to}
                isActive={location.pathname === item.to}
                disabled={item.disabled}
                exact={item.exact.toString()}
                className="relative"
              >
                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                {item.label}
              </CustomNavLink>
              <SubMenu item={item} location={location} />
            </li>
          ))}
        </ul>
        {user && (
          <ul className="-mx-8 space-y-1">
            <li>
              <NavLink
                className="transition-colors duration-300 no-underline hover:bg-customHighlightColor group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer text-customLightGray hover:bg-customHighlightColor"
                to={"#"}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <div className="flex items-center space-x-2">
                  <ChatBubbleLeftIcon
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  <span>Conversations</span>
                </div>
                <div className="flex items-center justify-center bg-customHighlightColor w-6 h-6 rounded-full text-customLightGray text-xs">
                  {conversations.length || 0}
                </div>
              </NavLink>
              <ul
                className={`ml-1 space-y-1 ${
                  isExpanded ? "visible" : "hidden"
                }`}
              >
                {conversations.map((conversation) => (
                  <li key={conversation.conversation_id}>
                    <CustomNavLink
                      to={`/general_data_chat/${conversation.app_instance_id}/${conversation.conversation_id}`}
                      className="w-56" // Additional custom class if needed
                    >
                      {/* <span className="h-6 w-6 shrink-0" aria-hidden="true"> */}{" "}
                      {/* Placeholder for an icon, replace with actual icon if needed */}
                      {/* </span> */}
                      <div>
                        <div className="font-semibold">
                          {conversation.title}
                        </div>
                        <div className="text-sm text-gray-500">
                          {conversation.created_at.replace("2024", " ")}
                        </div>
                      </div>
                    </CustomNavLink>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        )}

        {/* <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    Your teams
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li> */}
        <li className="mt-auto">
          <p className="text-gray-400 text-sm">
            <span>
              Powered by{" "}
              <a
                href="https://www.tellen.ai"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline text-gray-400 hover:underline hover:text-white"
              >
                Tellen
              </a>
              .
            </span>
          </p>
          {/* <SignedIn>
            <Link
              to="/profile"
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white no-underline"
            >
              <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              Account Settings
            </Link>
          </SignedIn>
          <SignedOut>
            <Link
              to="/sign_in"
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white no-underline"
            >
              <InboxIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              Create Free Account
            </Link>
          </SignedOut> */}
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
