import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequest, postRequest } from "../utils/httpUtils";

const addConversationEndpoint = "/api/add_conversation";
const getConversationEndpoint = (uuid) => `/api/get_conversation/${uuid}`;

/**
 * Initializes and manages a Conversation.
 *
 * @returns {object} - Object containing the Conversation state and functions to manage the chat.
 */
const useConversation = () => {
  const { conversation_id: paramConversationID } = useParams();
  const [conversationId, setConversationId] = useState(paramConversationID || null)
  const [conversationLoaded, setConversationLoaded] = useState(false)

  const loadConversation = useCallback(async () => {
    if (conversationId) {
      try {
        const { data } = await getRequest(
          getConversationEndpoint(conversationId)
        );
        setConversationId(data.conversation.conversation_id);
        setConversationLoaded(true)
        return data;
      } catch (error) {
        console.error("Failed to fetch Conversation:", error);
        throw error;
      }
    }
  }, [conversationId]);

  const createConversationInternal = useCallback(
    async (chatData) => {
      try {
        const { data } = await postRequest(addConversationEndpoint, chatData);
        setConversationId(data.conversation_id);
        setConversationLoaded(true)
        return data;
      } catch (error) {
        console.error("Failed to create Conversation:", error);
        throw error;
      }
    },
    []
  );

  const createConversation = useCallback(
    (appInstanceId) => {
      if (!conversationId && !conversationLoaded) {
        createConversationInternal({ app_instance_id: appInstanceId }).catch(
          (error) => {
            console.error("Failed to create Conversation:", error);
          }
        );
      }
    },
    [
      conversationId,
      conversationLoaded,
      createConversationInternal,
    ]
  );

  return {
    conversationId,
    createConversation,
    loadConversation,
  };
};

export default useConversation;
